import React from 'react'
import Navbar from './components/Navbar'
import Homesection from './components/Homesection'
import Footer from './components/Footer'

const Home = () => {
  return (
    <>
         <Navbar></Navbar>
         <Homesection></Homesection>
         <Footer></Footer>
    </>
  )
}

export default Home
    