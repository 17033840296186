import React from 'react'

const Homesection = () => {
  return (
    <>
    <div className="home-outer-container">
        <div className="container">
            <div className='home-section d-flex align-items-center text-center justify-content-center'>
                <div className="col-md-10 col-12">
                    <h1 className='text-white'>
                        We design, develop and modernize <span> software applications
                        </span>                    </h1>
                </div>
            </div>            
        </div> 
    </div>    
    </>
  )
}

export default Homesection
