import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import serviceapi from '../Api/serviceapi'
import Footer from '../components/Footer';

const Services = () => {
    const [servicesData, SetUserData] = useState(serviceapi);
  return (
    <>
      <Navbar></Navbar>
        <div className="container mt-5">
            <div className="col-md-12 pt-5 text-center mx-auto col-12">
                <p className='pb-0'>Services we offer to our clients</p>
                <h1 className='text-white pb-3' > Our Services </h1>
                <div className='animated-bar text-center mx-auto'></div>

                <div className="row mt-5">
                    {servicesData.map((curElem) => {
                            const {id, icon, title, info} = curElem;
                        
                        return( 
                            <>
                        <div className="col-12 col-md-6 d-flex col-lg-4">
                            <div class="single-service w-100">
                                <i className={icon}> </i>
                                <h6 className="service-title">{title}</h6>
                                <p className="service-description">
                                    {info}
                                </p>
                            </div>
                        </div>    
                        </>
                    
                        )
                    })}

                </div>
            </div>    
        </div>

        <Footer></Footer>
    </>
  )
}

export default Services
