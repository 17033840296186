import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import aboutapi from '../Api/aboutapi'
import Footer from '../components/Footer';


const About = () => {
  const [aboutData, setUserData] = useState(aboutapi);
  return (
    <>  
        <Navbar></Navbar>
        <div className="container mt-5">

            {aboutData.map((curElem) => {
              const {id, subtitle, title, subheading, info } = curElem ;
                return (
                <>
              <div className="col-md-8 pt-5 mx-auto col-12">
                  <p className='pb-0'>{subtitle}</p>
                  <h1 className='text-white pb-3' > {title}</h1>
                  <div className='animated-bar'></div>

                  <div className='col-md-12 mt-5 text-start'>
                      <h4 className='mb-3 text-green'>{subheading}</h4>
                      <p>{info}</p>
                  </div>
              </div>   

              </>
                )
                
              })}

        </div>

        <Footer></Footer>
    </>
  )
}

export default About
