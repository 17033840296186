import React, { useState } from 'react';
 import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [show, setShow] = useState(false) 
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-tranparent">
          <div className="container-fluid">
              <NavLink className="navbar-brand" to="/"><img className='img-fluid' src="images/trulogic-online.png" alt="TruLogic Online"/></NavLink>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShow(!show)}>

              <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${show ? "show" : " " }`} id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/about-us">About Us</NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link" to="/services">Services</NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link" to="/contact">Contact</NavLink>
                  </li>
              </ul>
            </div>
          </div>
      </nav>
    </>
  )
}

export default Navbar
