const aboutapi = [
    {
        id: "1",
        subtitle:"The Right Products, Built Right.",
        title:"About Us",
        subheading:"We help your business thrive with transformative software development.",
        info:"TruLogic Online is a leading development company with the desire to be the best and produces the best results. We have good stability of designers, programmers, and team leaders. Also, we have expertise in Website Designing, Website Development, E-commerce Solutions, Project Outsourcing, Branding, SMO (Search Media Optimization), SEO (Search Engine Optimization), Website Promotion & Hosting, Domain Registration, and Online Apps.",
    }
]

export default aboutapi
