import React from 'react'

const Footer = () => {
  return (
    <>
     <div className="container text-center">
        <div className="col-md-12 pt-3">
            <p>&copy; 2023 - 2024 Trulogic Online. All Rights Reserved.</p>
        </div>
     </div> 
    </>
  )
}

export default Footer
