  import React from 'react';
  import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

  //import logo from './logo.svg';
  // import { getDatabase, ref, set } from "firebase/database";
  // import { app } from "./firebase";
  import './App.css';
  import Home from './Home';
  import About from './pages/About';
  import Services from './pages/Services';
  import Contact from './pages/Contact';


  const App = () =>{

    return(
      <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/services" element={<Services></Services>} />
          <Route path="/contact" element={<Contact></Contact>} />

        </Routes>
      </>
    )


  };

  export default App;
