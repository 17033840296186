const homeservicesapi = [

    {
        id: "1",
        icon:"fa-sharp fa-solid fa-laptop fa-fw service-icon",
        title:"Web Development",
        info:"PHP, Laravel, Cake PHP, Wordpress, YII, HTML5, CSS, SASS",
    },

    {
        id: "2",
        icon:"fa-solid fa-object-group fa-fw service-icon",
        title:"Design",
        info:"User Experience Responsive Web Design",
    },

    {
        id: "3",
        icon:"fa-solid fa-mobile-button fa-fw service-icon",
        title:"Mobile Development",
        info:"iPhone Apps Development Android Development",
    },

    {
        id: "4",
        icon:"fa-solid fa-person-military-to-person fa-fw service-icon",
        title:"IT Consulting",
        info:"Web Strategy Consulting Digital Media Consulting",
    },

    {
        id: "5",
        icon:"fa-solid fa-gear-complex fa-fw service-icon",
        title:"Other Services",
        info:"QA and Maintenance Performance Testing",
    },
]

export default homeservicesapi