import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';

const Contact = () => {
  return (
    <>

      <Navbar></Navbar>  
      <div className="container mt-5">
        <div className="col-md-12 text-center">
            <p className='pb-0'>Feel free to contact us anytimes</p>
            <h1 className='text-white pb-3' >Get in Touch</h1>
            <div className='animated-bar mx-auto'></div>
        </div>
        <div className="col-md-9 mx-auto col-12 mt-5 pt-5">
            <h4 className='content-title'>Contact Info</h4>
            <p>Always available for the work if the right project comes along, Feel free to contact us!</p>
        

            <ul class="list-unstyled list-info contact-list">

                      <div class="row">
                        <li class="col-md-6 col-12 mb-3">
                          <div class="media align-items-center">
                            <span class="info-icon"><i class="fa-brands fa-nutritionix fa-fw"></i></span>
                            <div class="media-body info-details">
                              <h6 class="info-type">Name</h6><span class="info-value">TruLogic Online</span>
                            </div>
                          </div>
                        </li>
                        <li class="col-md-6 col-12 mb-3">
                          <div class="media align-items-center">
                            <span class="info-icon"><i class="fa-solid fa-location-arrow-up fa-fw"></i></span>
                            <div class="media-body info-details">
                              <h6 class="info-type">Location</h6><span class="info-value">329 A, Palam Vihar, Pakhowal Road, Ludhiana.</span>
                            </div>
                          </div>
                        </li>
                        <li class="col-md-6 col-12 mb-3">
                          <div class="media align-items-center"><span class="info-icon"><i class="fa-sharp-duotone fa-solid fa-phone fa-fw"></i></span>
                            <div class="media-body info-details">
                              <h6 class="info-type white-text">Call Us</h6><span class="info-value"><a href="tel:+441632967704">+91 9855129977</a></span>
                            </div>
                          </div>
                        </li>
                        <li class="col-md-6 col-12 mb-3">
                          <div class="media align-items-center"><span class="info-icon"><i class="fa-regular fa-envelope fa-fw"></i></span>
                            <div class="media-body info-details">
                              <h6 class="info-type white-text">Email Us</h6><span class="info-value"><a href="mailto:info@trulogiconline.com">info@trulogiconline.com</a></span>
                            </div>
                          </div>
                        </li>

                        

                        
                      </div> 
                    </ul>

        </div>


      </div>

      <Footer></Footer> 
    </>
  )
}

export default Contact
